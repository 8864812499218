import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from "react"
import { graphql } from "gatsby"
import { navigate } from '@reach/router'
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { Sidebar, RefBar } from '../components/sidebar'
import { isLoggedIn } from '../services/auth'
import { Banner } from '../components/banner'
import { handleCmsFile } from '../services/api'


var _ = require('lodash');
var marked = require("marked")

class mdTemplate extends Component {
  constructor(props) {
    super(props);

      this.state = {
          objItems: false,
      }; 
  }

  async componentDidMount(){
    if(!isLoggedIn()){
      navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
    }
    this.forceUpdate()

    var path = window.location.pathname
    var filename = ''

    if(path.includes('guide')){
      filename = 'staging-enets-guide.md'
    }
    else if(path.includes('overview')){
      filename = 'staging-enets-overview.md'
    }
    else if(path.includes('web')){
      filename = 'staging-enets-web.md'
    }
    else if(path.includes('ios')){
      filename = 'staging-enets-ios.md'
    }
    else if(path.includes('android')){
      filename = 'staging-enets-android.md'
    }
    else if(path.includes('others')){
      filename = 'staging-enets-others.md'
    }
    else if(path.includes('netspay')){
      filename = 'staging-netspay.md'
    }
    else if(path.includes('qr')){
      filename = 'staging-qr-guide.md'
    }
    
    let result
    var data = {
        stage_file: filename,
        status: "current"
    }

    
    let files = await handleCmsFile(data)
    // console.log(files)
    files.data.forEach(function(item,key){
        if(item.status === 'current'){
            result = item.data      
        }
    })

    if(result != null || result != undefined)
      this.setState({
        objItems: marked(result)
      })
    else
      this.setState({
        objItems: false
      })
    // console.log(result)
  }

  UNSAFE_componentWillUpdate(){
    if(!isLoggedIn()){
      navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
    }
  }

  render(){
    if(isLoggedIn()){
      const { markdownRemark } = this.props.data
      const { frontmatter, html } = markdownRemark
      var content = ''
      if(this.state.objItems == false)
         content = html
      else
         content = this.state.objItems

      return (


        <Layout>
          <Banner title={frontmatter.title} />
          <Container fluid>
            <Row>
              <Col md={2} className="d-sm-block d-lg-block">
                {frontmatter.type === "api-guide" ? <Sidebar section={frontmatter.section}/> :  <RefBar section={frontmatter.section}/> }
              </Col>
              <Col lg={10} md={12} sm={12}>
                <div className="blog-post-container">
                  <div className="blog-post">
                    <div
                      className="blog-post-content"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Layout>
      )
    } else {
      return ''
    }
  }
}

mdTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default mdTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        type
        section
      }
    }
  }
`